<template>
  <div class="dashboard-editor-container">
    <template v-if="isIndexAuth('99')">
      <div class="default-logo">
        <p> {{ $t("welcome_colorx") }} </p>
        <img src="@/assets/index.png" />
      </div>
    </template>
    <template v-else>
      <el-row :gutter="15" style="margin-bottom: 15px">
        <el-col :xs="24" :sm="24" :lg="12">
          <basic-info />
        </el-col>
        <el-col :xs="24" :sm="24" :lg="4">
          <qr-code />
        </el-col>
        <el-col :xs="24" :sm="24" :lg="8">
          <box-card v-if="isIndexAuth('1') || isIndexAuth('2') || isIndexAuth('3')" />
        </el-col>
      </el-row>

      <el-row
        :gutter="15"
        v-if="isIndexAuth('1') && isIndexAuth('2') && isIndexAuth('3')"
      >
        <el-col v-if="isIndexAuth('1')" :xs="24" :sm="24" :lg="16">
          <div class="chart-wrapper">
            <overview-list title="产品概览" />
          </div>
        </el-col>
        <el-col v-if="isIndexAuth('2')" :xs="24" :sm="24" :lg="8">
          <div class="chart-wrapper">
            <overview-list title="设备概览" />
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="15"
        v-if="isIndexAuth('1') && isIndexAuth('2') && isIndexAuth('3')"
      >
        <el-col v-if="isIndexAuth('3')" :xs="24" :sm="24" :lg="14">
          <div class="chart-wrapper">
            <overview-list title="搜索概览" />
          </div>
        </el-col>
        <el-col v-if="isIndexAuth('1')" :xs="24" :sm="24" :lg="10">
          <div class="chart-wrapper">
            <overview-list title="搜索匹配率" />
          </div>
        </el-col>
      </el-row>

      <el-row
        style="background: #fff; margin-bottom: 15px"
        v-if="isIndexAuth('7')"
      >
        <el-card>
          <div slot="header" class="clearfix">
            <span>工厂概览</span>
          </div>
          <factory-overview />
        </el-card>
      </el-row>

      <el-row :gutter="15">
        <el-col :xs="24" :sm="24" :lg="12" v-if="isIndexAuth('5')">
          <div class="chart-wrapper">
            <el-card>
              <div slot="header" class="clearfix">
                <span>搜索趋势</span>
              </div>
              <div style="padding-left: 67px; font-size: 14px; color: #999">
                搜索次数
              </div>
              <div
                style="
                  padding-left: 67px;
                  font-size: 24px;
                  color: #999;
                  padding-top: 10px;
                "
                ref="searchCount"
              >
                {{ searchCount }}
              </div>
              <raddar-chart
                :bgColor="bgColor1"
                tData="searchCountData"
                @searchcount="searchcount"
              />
              <div
                style="
                  border-bottom: 1px solid #ededed;
                  margin: 30px 155px 30px 77px;
                "
              ></div>
              <div style="padding-left: 67px; font-size: 14px; color: #999">
                搜索匹配率
              </div>
              <div
                style="
                  padding-left: 67px;
                  font-size: 24px;
                  color: #999;
                  padding-top: 10px;
                "
                refs="searchAccuracyRate"
              >
                {{ searchAccuracyRate }}%
              </div>
              <raddar-chart
                :bgColor="bgColor"
                tData="searchAccuracyRateData"
                @searchaccuracyrate="searchaccuracyrate"
              />
            </el-card>
          </div>
        </el-col>
        <el-col
          v-if="
            isIndexAuth('1') &&
            isIndexAuth('9') &&
            isIndexAuth('10') &&
            !isIndexAuth('5')
          "
          :xs="24"
          :sm="24"
          :lg="12"
        >
          <div class="chart-wrapper">
            <overview-list title="产品概览" />
          </div>
          <div class="chart-wrapper">
            <overview-list title="产品新增" />
          </div>
          <div class="chart-wrapper">
            <overview-list title="员工概况" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :lg="12" v-if="isIndexAuth('4')">
          <div class="chart-wrapper">
            <el-card body-style="height:450px;">
              <div slot="header" class="clearfix">
                <!-- <span>产品分类分布</span>  -->
                <!-- <el-select v-model="productAttrId" placeholder="">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.attrName"
                    :value="item.productAttrId">
                  </el-option>
                </el-select> -->
                <el-dropdown @command="selectproductAttrId">
                  <div class="dropdown" style="font-size: 16px">
                    {{ productAttrId
                    }}<i class="el-icon-caret-bottom" style="float: right"></i>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="item.attrName"
                        v-for="item in categoryList"
                        :key="item.id"
                        >{{ item.attrName }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <img
                  src="@/assets/switch.png"
                  class="switch_img"
                  @click="changetype"
                />
              </div>
              <div class="no-more" v-if="pData && pData.length === 0">
                <img src="@/assets/no-more.png" />
                <span>暂无数据</span>
              </div>
              <pie-chart
                :chartIsLoad="false"
                :chartData="pData"
                @piechartemit="piechartemit"
                v-if="pData && pData.length > 0 && type == '2'"
                ref="children"
              />
              <template v-if="type == '1' && pData.length > 0">
                <el-table
                  :data="pData"
                  stripe
                  height="410"
                  style="width: 100%"
                  :header-cell-style="{ background: '#f7f8fa' }"
                >
                  <el-table-column
                    prop="categoryName"
                    label="类别名称"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column prop="count" label="数量" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="proportion"
                    label="占比"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </template>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <el-row
        style="background: #fff; margin-bottom: 15px"
        v-if="isIndexAuth('6')"
      >
        <el-card>
          <div slot="header" class="clearfix">
            <span>产品录入排行</span>
            <div style="float: right; padding: 3px 0">
              <date-picker @dateChange="dateChange" />
            </div>
          </div>
          <div class="no-more" v-if="bData.length === 0">
            <img src="@/assets/no-more.png" />
            <span>暂无数据</span>
          </div>
          <el-row :gutter="15" v-else>
            <el-col :xs="24" :sm="24" :lg="16">
              <bar-chart ref="barChart" @barchartemit="barchartemit" />
            </el-col>
            <el-col :xs="24" :sm="24" :lg="8">
              <rank-info ref="rankInfo" />
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </template>
    <Footer style="margin-top: -30px; width: 100%" />
  </div>
</template>

<script>
// import GithubCorner from '@/components/GithubCorner'
import PanelGroup from './components/PanelGroup'
import RankInfo from './components/RankInfo'
import LineChart from './components/LineChart'
import RaddarChart from './components/RaddarChart'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import TransactionTable from './components/TransactionTable'
// import TodoList from './components/TodoList'
import BoxCard from './components/BoxCard'
import OverviewList from './components/overviewList'
import { selCatDist } from '@/api'
import { mapState } from 'vuex'
import { selectProductAttrList } from '@/api/modules/productattr'
import { selectInputStatistics } from '@/api'

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
}

export default {
  name: 'DashboardAdmin',
  components: {
    // GithubCorner,
    PanelGroup,
    LineChart,
    RaddarChart,
    PieChart, //chartIsLoad
    BarChart,
    TransactionTable,
    // TodoList,
    BoxCard,
    OverviewList,
    RankInfo,
    BasicInfo: () => import('./components/BasicInfo'),
    QrCode: () => import('./components/QrCode'),
    // RankInfo: () => import("./components/RankInfo"),
    FactoryOverview: () => import('./components/FactoryOverview'),
    DatePicker: () => import('./components/DatePicker'),
    Footer: () => import('./components/Footer')
  },
  computed: {
    // searchCount() {
    //   return localStorage.getItem('searchCount')
    // },
    // searchAccuracyRate() {
    //   return localStorage.getItem('searchAccuracyRate')
    // },
    ...mapState({
      // 1、产品概览 2、设备概览 3、搜索概览 4、产品分类分布 5、搜索趋势 6、产品录入排行 7、工厂概览AND产品分类分布 8、品牌信息
      indexPermissions: (state) => state.permission.indexPermissions //当前登录的账号
    })
  },
  data() {
    return {
      categoryList: [],
      productAttrId: '',
      searchCount: 0,
      searchAccuracyRate: 0,
      pData: [''],
      pDatashow: true,
      bData: [''],
      serNum: 0,
      serRercentage: '0%',
      bgColor: [
        'rgba(145, 238, 107, 1)',
        'rgba(145, 238, 107, 0.4)',
        'rgba(145, 238, 107, 0)'
      ],
      bgColor1: [
        'rgba(78,151,254,1)',
        'rgba(78,151,254,0.4)',
        'rgba(78,151,254,0)'
      ],
      lineChartData: lineChartData.newVisitis,
      type: '1'
    }
  },
  created() {
    this.selectProductAttrList()
  },
  mounted() {
    console.log(this.indexPermissions)
    this.dateChange()
  },
  beforeDestroy() {},
  methods: {
    changetype() {
      if (this.type == '1') {
        this.type = '2'
      } else {
        this.type = '1'
      }
    },
    async selCatDist() {
      var params = {
        productAttrId: this.categoryList.find((item) => {
          if (item.attrName == this.productAttrId) {
            return item
          }
        }).productAttrId,
        brandId: sessionStorage.getItem('BrandInfo'),
        factoryId: sessionStorage.getItem('factoryId')
      }
      selCatDist(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.pData = data.data
          // this.pData = []
        } else {
          this.pData = []
        }
      })
    },
    selectProductAttrList() {
      var params = {
        isUniversal: '0'
      }
      selectProductAttrList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.categoryList = data.productAttrList
          this.productAttrId = data.productAttrList[0].attrName
          this.selCatDist()
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    selectproductAttrId(id) {
      this.pData = false
      this.productAttrId = id
      this.selCatDist()
    },
    searchcount(data) {
      this.searchCount = data
    },
    searchaccuracyrate(data) {
      this.searchAccuracyRate = data
    },
    piechartemit(data) {
      this.pData = data
    },
    barchartemit(data) {
      // console.log(data);
      this.bData = data
    },
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type]
    },
    async selectInputStatistics(value = ['', '']) {
      const params = {
        startTime: value[0] ? value[0] + ' 00:00:00' : '',
        endTime: value[1] ? value[1] + ' 23:59:59' : '',
        limit: 10
      }
      const { data } = await selectInputStatistics(params)
      if (data && data.code === 0) {
        this.$refs.barChart.selectInputStatistics(data.data)
        this.$refs.rankInfo.selectInputStatistics(data.data)
      }
    },
    dateChange(value) {
      if (value === null) {
        value = ['', '']
      }
      this.$nextTick(() => {
        // console.log(9999, this.$refs);
        setTimeout(() => {
          if (this.$refs.rankInfo) {
            this.$refs.rankInfo.selectInputStatistics(value)
          }
          if (this.$refs.barChart) {
            this.$refs.barChart.selectInputStatistics(value)
          }
        }, 0)
      })
      // this.pData = [""];
      this.bData = ['']
    }
  }
}
</script>

<style lang="scss" scoped>
.default-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 1000px;
  padding-top: 200px;
}
.dashboard-editor-container {
  padding: 15px 15px 10px 15px;
  background-color: #eaf1fc;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    // padding: 16px 16px 0;
    margin-bottom: 15px;
  }
}
.switch_img {
  width: 20px;
  height: 20px;
  float: right;
}
@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
.no-more {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 411px;
  span {
    font-size: 14px;
    color: #000;
    padding-top: 10px;
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.chart-wrapper {
  .el-table th.is-leaf {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
  .my-el-select /deep/ .el-input .el-input__inner {
    border: none !important;
  }
}
</style>
